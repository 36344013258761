import { getActivePinia } from 'pinia'

import { useUserInfluencerTagsStore } from '~/stores/userInfluencerTags'

export default defineNuxtRouteMiddleware(async (to) => {
  const { $localePath } = useNuxtApp()
  const pinia = getActivePinia()
  const { FETCH: FETCH_USER_INFLUENCER_TAGS } =
    useUserInfluencerTagsStore(pinia)

  if (to.query.uid && to.query.event) return navigateTo($localePath('/'))

  await Promise.allSettled([FETCH_USER_INFLUENCER_TAGS()])
})
